import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
// Layout
import SecondaryLanding from "../layouts/secondary-landing";

// Components
import HeroChevron from "../hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../seo/seo";
import MortgageCalculator from "../../components/calculators/mortgage-calculator/calculator";
import FaqAccordion from "../faq/faq-accordion";
import Icon from "../custom-widgets/icon";
import ReturningBorrowerLink from "../home-loans/returning-borrower-link";

import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import NewUIWafdBankBenefits from "../wafd-bank-benefits/new-ui-wafd-bank-benefits";
import CustomLargeCards from "../card/custom-large-cards";
import StretchedLinkCards from "../card/stretched-link-cards";
import HelpSection from "../home-loans/help-section";
import WaFdBuiltPartner from "../home-loans/wafd-built-partner";

export const query = graphql`
  query stateConstructionLoansHeroQuery(
    $slug: String
    $pathXXL: String
    $pathXL: String
    $pathLG: String
    $pathMD: String
    $pathSM: String
    $pathXS: String
    $pathXXS: String
  ) {
    imgVariableXXL: file(relativePath: { eq: $pathXXL }) {
      ...heroChevronImageFragmentXXL
    }
    imgVariableXL: file(relativePath: { eq: $pathXL }) {
      ...heroChevronImageFragmentXL
    }
    imgVariableLG: file(relativePath: { eq: $pathLG }) {
      ...heroChevronImageFragmentLG
    }
    imgVariableMD: file(relativePath: { eq: $pathMD }) {
      ...heroChevronImageFragmentMD
    }
    imgVariableSM: file(relativePath: { eq: $pathSM }) {
      ...heroChevronImageFragmentSM
    }
    imgVariableXS: file(relativePath: { eq: $pathXS }) {
      ...heroChevronImageFragmentXS
    }
    imgVariableXXS: file(relativePath: { eq: $pathXXS }) {
      ...heroChevronImageFragmentXXS
    }
    strapiBranchState: strapiBranchStates(Slug: { eq: $slug }) {
      StateCode
      StateName
      Slug
    }
    CardImage1: file(relativePath: { eq: "cards/articles-cards/thumbnail-buying-land-build-house-071023.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    CardImage2: file(relativePath: { eq: "cards/articles-cards/thumbnail-construction-to-permanent-loan-010824.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    CardImage3: file(relativePath: { eq: "cards/articles-cards/thumbnail-building-vs-buying-061623.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    largeCard1: file(relativePath: { eq: "cards/personalization/thumbnail-closing-costs-03152023-730.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
    largeCard2: file(relativePath: { eq: "cards/articles-cards/thumbnail-construction-to-permanent-loan-010824.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 600
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
  }
`;

const StateConstructionLoansTemplate = ({ data }) => {
  const stateData = data.strapiBranchState;
  const StateName = stateData.StateName;
  const StateCode = stateData.StateCode;
  const title = `${StateName} Construction`;

  const heroData = {
    AZ: { imgDateStamp: "031623", altText: "New home construction below the Santa Catalina Mountains in Arizona." },
    ID: { imgDateStamp: "03162023", altText: "New home construction in Coeur d'Alene, Idaho" },
    OR: { imgDateStamp: "031623", altText: "New home construction in Bend, Oregon." },
    WA: { imgDateStamp: "031623", altText: "New home construction in Spokane Valley, Washington." },
    NM: { imgDateStamp: "031623", altText: "New home construction in Santa Fe, New Mexico." },
    UT: { imgDateStamp: "03152023", altText: "New home construction in Spanish Fork, Utah." },
    NV: { imgDateStamp: "031623", altText: "New home construction in Reno, Nevada." },
    TX: { imgDateStamp: "031623", altText: "New home construction in Houston, Texas." }
  };

  const heroChevronData = {
    id: "state-construction-hero-chevron",
    ...getHeroImgVariables(data),
    altText: heroData[StateCode].altText,
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Build Your Dream Home in " + StateName
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-1",
            text: "Find a Loan Officer",
            url: "/personal-banking/contact-loan-officer",
            class: "btn-white",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-2",
            text: "Use Loan Calculator",
            url: "#mortgage-calculator-section",
            class: "btn-light",
            containerClass: "mb-3",
            icon: {
              position: "right",
              lib: "fas",
              name: "arrow-down",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: title
    }
  ];

  const stretchedCardsData = {
    sectionClass: "text-left pt-0",
    rowColsClass: "row-cols-1 row-cols-md-3",
    title: "",
    titleClass: "text-center",
    hasGrowEffect: false,
    hasRiseShadowEffect: true,
    useMobileView: false,
    cards: [
      {
        id: "mb-card-1",
        url: "/blog/building-remodeling/buying-land-to-build-house",
        image: {
          imgVariable: data.CardImage1.childImageSharp.gatsbyImageData,
          altText: "Wood model house in empty field at sunset."
        },
        title: "7 Tips for Buying Land to Build a House",
        titleClass: "text-green-60",
        text: "Many parts of the country are experiencing housing shortages and experts predict a building boom is just around the corner."
      },
      {
        id: "mb-card-2",
        url: "/blog/building-remodeling/construction-to-permanent-loans-explained",
        image: {
          imgVariable: data.CardImage2.childImageSharp.gatsbyImageData,
          altText: "House project in progress."
        },
        title: "Construction-to-Permanent Loans Explained",
        titleClass: "text-green-60",
        text: "It's hard to find everything you want in a home."
      },
      {
        id: "mb-card-3",
        url: "/blog/building-remodeling/building-vs-buying-house",
        image: {
          imgVariable: data.CardImage3.childImageSharp.gatsbyImageData,
          altText: "Couple talking about the floor-plan of their new house."
        },
        title: "Building vs Buying a House - Which is Better?",
        titleClass: "text-green-60",
        text: "One of our most commonly asked questions is should I buy or build a house?"
      }
    ]
  };

  const largeCardsImgData = {
    largeCard1: data.largeCard1,
    altTextCard1: "Young couple moving in new house, holding keys.",
    largeCard2: data.largeCard2,
    altTextCard2: "House project in progress."
  };

  const largeCardsData = [
    {
      title: "Save Up to $600 on Closing Costs With a Checking Account From WaFd Bank*",
      disclaimer:
        "*To qualify for the maximum $600 discount, before closing documents are prepared you must (1) have or open the WaFd checking account, (2) register for EZ-Pay from the new checking account, and (3) sign up for direct deposit of at least one form of a monthly recurring source of income.",
      mainButton: {
        url: "/personal-banking/home-loans/closing-cost-checking-account-offer",
        title: "Find Out How"
      }
    },
    {
      title: "Our Custom Construction Loan Advantage",
      contentListGreenCircleChecked: [
        {
          content: (
            <p id="large-card-2-item-1">
              Save up to $600 on closing costs with a checking account from WaFd Bank.*{" "}
              <a id="large-card-2-item-1-link" href="/personal-banking/home-loans/closing-cost-checking-account-offer">
                Find out how
                <Icon name="arrow-right" class="ml-1" />
              </a>
            </p>
          )
        },
        {
          content: <p id="large-card-2-item-2">We don't sell, assign, or transfer your loan.</p>
        },
        {
          content: (
            <p id="large-card-2-item-3" className="mb-0">
              Once construction is complete, the construction loan automatically becomes a mortgage.
            </p>
          )
        }
      ],
      mainButton: {
        title: "Watch Video"
      },
      videoData: {
        VimeoID: "840195560"
      }
    }
  ];

  const calculatorData = {
    title: StateName + " Construction Loan",
    loanType: "Construction",
    loanTerm: "30 Year Fixed",
    homeValue: 350000,
    interestRate: 0,
    estimatedPayment: 0,
    purchasePrice: 400000,
    loanAmount: 100000
  };

  const helpSectionData = {
    sectionClass: "bg-light",
    text: "If you're a first time home buyer and have done all your research but want help, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have.",
    statePage: {
      StateCode,
      StateName,
      stateData
    }
  };

  const metaData = {
    description: {
      AZ: "Need a construction loan in Arizona? Learn about WaFd Bank's single close construction loans. Find an AZ bank branch in Phoenix, Tucson, Yuma and beyond.",
      NM: "Need a construction loan in New Mexico? Learn about WaFd Bank's Construction-to-Permanent loans. Find a NM branch in Albuquerque, Las Cruces, Santa Fe, & beyond.",
      NV: "Need a construction loan in Nevada? Learn about WaFd Bank's Construction-to-Permanent loans. Find a NV branch in Las Vegas, Henderson, Elko, Fernley & beyond.",
      OR: "Need an Oregon construction loan? Learn about WaFd Bank's single close construction loans. Find an OR branch in Portland, Bend, Klamath Falls & beyond. ",
      ID: "Need a construction loan in Idaho? Learn about WaFd Bank's single close construction to permanent loans. Find an Idaho branch in Boise, Nampa and beyond.",
      UT: "Need a construction loan in Utah? Learn about WaFd Bank's Construction-to-Permanent loans. Find a UT branch in Salt Lake City, Layton, Logan, Murray, & beyond.",
      WA: "Need a construction loan in Washington State? Learn about WaFd Bank's Construction-to-Permanent loans. Find a branch in Seattle, Spokane, Bellevue, and beyond.",
      TX: "Need a construction loan in Texas? Calculate your WaFd Bank construction to permanent loan with branches located in Dallas, Austin, Plano, & Richardson."
    },
    title: {
      AZ: "Arizona Construction Loans - One Time Close",
      NM: "Construction Loans in New Mexico",
      NV: "Construction Loans in Nevada",
      OR: "Oregon Construction Loans",
      ID: "Construction Loans in Idaho",
      UT: "Construction Loans in Utah",
      WA: "Construction Loans in Washington State",
      TX: "One Time Close Construction Loan in Texas"
    }
  };

  const ogUrl = "https://www.wafdbank.com/personal-banking/home-loans/construction-loans/" + stateData.Slug;

  const SEOData = {
    title: metaData.title[StateCode],
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: metaData.title[StateCode]
      },
      {
        name: "description",
        property: "og:description",
        content: metaData.description[StateCode]
      },
      {
        property: "og:url",
        content: ogUrl
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content:
          "https://www.wafdbank.com/images/adsearch/states/og-construction-" +
          stateData.Slug +
          "-" +
          heroData[StateCode].imgDateStamp +
          ".jpg"
      }
    ]
  };

  const faqAccordionData = {
    id: `${StateName.toLowerCase().replace(/\s/g, "-")}-construction-loans-faq-accordion`,
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    seeMoreFaqsId: "construction-loans-see-more-faqs-link",
    faqCategoryNames: ["State Construction Loans"]
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const faqQuestionNameElement = document.getElementById("faq-question-state-name");
      const faqAnswerName1Element = document.getElementById("faq-answer-state-name-1");
      const faqAnswerName2Element = document.getElementById("faq-answer-state-name-2");
      if (faqQuestionNameElement && faqAnswerName1Element && faqAnswerName2Element) {
        faqQuestionNameElement.textContent = StateName;
        faqAnswerName1Element.textContent = StateName;
        faqAnswerName2Element.textContent = StateName;
      }
    }, 1000);

    // Clean up the timeout to prevent memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container py-0">
        <h1>Construction Loans in {stateData.StateName}</h1>
        <h3>
          Let's build your {stateData.StateName} dream home together. Our local professionals specialize in home
          construction loans and work with you and your builder from application through completion of your new home. We
          handle all draws and inspections during construction.
        </h3>
        <ReturningBorrowerLink loanType="Construction Loan" />
      </section>

      <MortgageCalculator {...calculatorData} />

      <CustomLargeCards noBackground imgData={largeCardsImgData} cardsData={largeCardsData} />

      <WaFdBuiltPartner />

      <NewUIWafdBankBenefits />

      <FaqAccordion {...faqAccordionData} />

      <StretchedLinkCards {...stretchedCardsData} />

      <HelpSection {...helpSectionData} />
    </SecondaryLanding>
  );
};

StateConstructionLoansTemplate.propTypes = {
  branch: PropTypes.object
};

export default StateConstructionLoansTemplate;

